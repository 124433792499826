import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import BreadCrumb from "../components/common/breadcrumb";
import { IBreadcrumbItem } from "../components/common/breadcrumb/breadcrumb_item";
import Pagination from "../components/common/pagination";

import ProductContainer from "../components/common/product/product_container";
import ProductItem from "../components/common/product/product_item";
import SEO from "../components/seo";
import Layout from "../layout/";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const breadcumbItems: IBreadcrumbItem[] = [
  {
    label: "products",
  },
];

const CategoryPage = ({ data, pageContext }) => {
  // Sort Product alphabetically

  const products: any[] = data.allStrapiProduct.nodes;

  return (
    <Layout>
      {/* {JSON.stringify(data)} */}
      <BreadCrumb items={breadcumbItems} />
      <div className="h-4"></div>
      <ProductContainer>
        {products.map((product) => (
          <motion.div
            className="item"
            variants={item}
            initial="hidden"
            animate="visible"
            key={product?.productName}
          >
            <ProductItem
              name={product?.productName}
              slug={product?.slug}
              image={getImage(product?.logo?.localFile)}
              logoBackgroundColor={product?.logoBackgroundColor}
            />
          </motion.div>
        ))}
      </ProductContainer>
      <div className="my-10">
        <Pagination {...pageContext} />
      </div>
    </Layout>
  );
};

export default CategoryPage;

export const Head = ({ location }) => {
  return (
    <SEO
      title={"製品カテゴリー | DXies"}
      description={
        "DXiesでは、デジタルトランスフォーメーションに欠かせない世界のクラウド製品をカテゴリーごとに掲載。貴重な日本語での情報をお届けします。"
      }
      path={location.pathname}
    />
  );
};

export const query = graphql`
  query AllProductsData($skip: Int!, $limit: Int!) {
    allStrapiProduct(
      sort: { fields: productName, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        productName
        slug
        logoBackgroundColor
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`;
