import React from "react";
import CustomLink from "../custom_link";

type PaginationItemProps = {
  isActive: boolean;
  children: React.ReactNode;
  to: string;
};

const PaginationItem = ({ isActive, children, to }) => (
  <CustomLink
    to={to}
    aria-current="page"
    className={`grid min-h-[40px] min-w-[40px] items-center  text-sm font-medium cursor-pointer
    ${
      isActive
        ? " rounded-full border border-dx-theme-link bg-dx-theme-link  text-white "
        : " text-gray-500 hover:rounded-full hover:border hover:border-dx-theme-item-highlight hover:bg-dx-theme-item-highlight "
    }
  `}
  >
    <span className="text-center">{children}</span>
  </CustomLink>
);

export default PaginationItem;
