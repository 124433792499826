import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { usePagination } from "../../../hooks/use_pagination";
import Link from "../custom_link";
import PaginationItem from "./pagination_item";

export type IPagination = {
  pageNumber: number;
  humanPageNumber: number;
  numberOfPages: number;
  previousPagePath: string;
  nextPagePath: string;
};

const Pagination = ({
  pageNumber,
  humanPageNumber,
  numberOfPages,
  previousPagePath,
  nextPagePath,
}) => {
  // const getPageList = () => {
  //   let startPage = humanPageNumber - 3;
  //   let endPage = humanPageNumber + 3;

  //   if (startPage <= 0) {
  //     endPage -= startPage - 1;
  //     startPage = 1;
  //   }

  //   if (endPage > numberOfPages) endPage = numberOfPages;

  //   length = endPage - startPage;

  //   return Array.from({ length }, (_, i) => i + startPage);
  // };

  const paginationRange = usePagination({
    currentPage: pageNumber,
    totalPageCount: numberOfPages,
    siblingCount: 2,
  });

  // console.log({ paginationRange });

  if (!paginationRange) {
    return <></>;
  }

  // If there are less than 2 times in pagination range we shall not render the component
  if (numberOfPages < 2) {
    return <></>;
  }

  return (
    <div className="grid justify-center">
      <nav className="inline-flex flex-wrap gap-0.5 " aria-label="Pagination">
        <Link
          to={previousPagePath}
          className="relative inline-flex cursor-pointer items-center justify-center min-h-[40px] min-w-[40px] text-sm font-medium text-gray-500 hover:rounded-full  hover:border-dx-theme-item-highlight hover:bg-dx-theme-item-highlight  "
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
        </Link>

        {paginationRange.map((pageNumber) => {
          let to = pageNumber === 1 ? "/products/" : `/products/${pageNumber}/`;
          if (pageNumber !== parseInt(pageNumber)) {
            to = "";
          }
          return (
            <PaginationItem
              to={to}
              key={pageNumber}
              aria-current="page"
              isActive={humanPageNumber === pageNumber}
            >
              {pageNumber}
            </PaginationItem>
          );
        })}

        <Link
          to={nextPagePath}
          className="relative inline-flex cursor-pointer items-center justify-center min-h-[40px] min-w-[40px] text-sm font-medium text-gray-500 hover:rounded-full hover:border  hover:border-dx-theme-item-highlight hover:bg-dx-theme-item-highlight  "
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
        </Link>
      </nav>
    </div>
  );
};

export default Pagination;
